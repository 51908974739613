'use client'

import {
	loginInfoType
} from "member/components/Pc/Login/LoginType"
import React, {
	useEffect, useState, type RefObject
} from "react"
import LoginMemberContView from "./LoginMemberContView/LoginMemberContView"

interface LoginMemberContProps {
	inputRef: RefObject<loginInfoType>;
	loginType: string;
	onChangeToggle: (active: boolean) => void;
	seller?: string;
}

const LoginMemberCont = ({
	seller,
	inputRef,
	loginType,
	onChangeToggle
}: LoginMemberContProps) => {
	const [
		loginInfo,
		setLoginInfo
	] = useState<loginInfoType>(inputRef.current)

	const onChangeCheckValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginInfo({
			...loginInfo,
			checkbox: event.target.checked
		})
	}

	const onChangePWDValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginInfo({
			...loginInfo,
			password: event.target.value
		})
	}

	const onChangePhoneNation = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setLoginInfo({
			...loginInfo,
			country: event.target.value
		})
	}

	const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginInfo({
			...loginInfo,
			phoneNumber: event.target.value
		})
	}

	const onUserLoginIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginInfo({
			...loginInfo,
			email: event.target.value
		})
	}

	const onUserLoginTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChangeToggle(event.target.value === `id`)
	}

	useEffect(() => {
		inputRef.current = loginInfo
	}, [
		loginInfo,
		inputRef
	])

	return (
		<LoginMemberContView
			loginInfo={loginInfo}
			loginType={loginType}
			onChangeCheckValue={onChangeCheckValue}
			onChangePWDValue={onChangePWDValue}
			onChangePhoneNation={onChangePhoneNation}
			onChangePhoneNumber={onChangePhoneNumber}
			onUserLoginIdChange={onUserLoginIdChange}
			onUserLoginTypeChange={onUserLoginTypeChange}
			seller={seller}
		/>
	)
}

export default LoginMemberCont
