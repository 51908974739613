'use client'

import Link from '@dg/common/components/Link'
import {
	classNameFunc, stringTrimFunc
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import Image from 'next/image'
import {
	PropsWithChildren
} from 'react'

interface LoginRewardLiViewProps {
	html?: string;
	image: string;
	link: string;
	type?: string;
}

const LoginRewardLiView = ({
	children, html, type = `text`, link, image
}: PropsWithChildren<LoginRewardLiViewProps>) => {
	const imageValidate = useImageValidate()

	const spanClassName = classNameFunc(`relative z-10 ml-1`)

	return (
		<li
			className="flex-1"
		>
			<Link
				href={link}
				target="_blank"
				className={
					classNameFunc(`
						relative inline-flex h-13 w-36 items-center overflow-hidden whitespace-nowrap rounded-md px-2
						${
							type === `text` ?
								`border border-solid border-[#517fef] font-bold` :
								`bg-[#749dfd] pl-2 pr-10 text-dg-white dark:text-dgdark-white`
						}
					`)
				}
			>
				<Image
					className={type === `bg` ? `object-cover` : undefined}
					fill={type === `bg` ? true : undefined}
					height={type === `text` ? 25 : undefined}
					onError={imageValidate.onError}
					sizes="24px"
					src={image}
					width={type === `text` ? 24 : undefined}
					alt={
						stringTrimFunc(`
							${
								type === `text` && children !== undefined && children !== null &&
								typeof children !== `object` ?
									children :
									``
							}
							${
								type === `bg` && html !== undefined && typeof html !== `object` ?
									html :
									``
							}
							Icon
						`)
					}
					priority
				/>

				{
					html !== undefined ?
						(
							<span
								className={spanClassName}
								dangerouslySetInnerHTML={
									{
										__html: html
									}
								}
							/>
						) :
						(
							<span
								className={spanClassName}
							>
								{children}
							</span>
						)
				}
			</Link>
		</li>
	)
}

export default LoginRewardLiView
