'use client'

import Button from "@dg/common/components/Button"
import Tooltip from '@dg/common/components/Tooltip'
import {
	classNameFunc, stringTrimFunc
} from "@dg/common/lib/common"
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import useTooltip from '@dg/common/lib/hooks/useTooltip'
import {
	QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import Radio from 'member/components/Common/Find/Radio/Radio'
import RadioGroup from 'member/components/Common/Find/Radio/RadioGroup'
import {
	loginInfoType
} from "member/components/Pc/Login/LoginType"
import LoginAdult from "member/components/Pc/Login/LoginView/LoginAdult/LoginAdult"
import LoginReward from 'member/components/Pc/Login/LoginView/LoginReward'
import LoginSnsArea from 'member/components/Pc/Login/LoginView/LoginSnsArea'
import Image from 'next/image'
import React, {
	type RefObject
} from 'react'
import {
	useTranslation
} from "react-i18next"
import LoginGuestAfterGoodsCont from "./LoginGuestAfterGoodsCont/LoginGuestAfterGoodsCont"
import LoginGuestCont from "./LoginGuestCont/LoginGuestCont"
import LoginGuideView from './LoginGuideView'
import LoginMemberCont from "./LoginMemberCont/LoginMemberCont"
import LoginTypeToggleView from "./LoginTypeToggleView"

interface LoginViewProps {
	RefLoginInfo: RefObject<loginInfoType>;
	alertMsg: string;
	isAdult?: boolean;
	isGuestAfterGoodsLogin?: boolean;
	isNoLoginQuestion: boolean;
	loginType: string;
	memberType: string;
	onChangeLoginRadio: (id: string) => void;
	onChangeToggle: (active: boolean) => void;
	onConfirmAdult: () => void;
	onLoginButtonClick: (event: React.FormEvent) => void;
	orderUrl: string;
	returnUrl: string;
	seller?: string;
}

const LoginView = ({
	RefLoginInfo,
	alertMsg,
	isAdult,
	isGuestAfterGoodsLogin,
	isNoLoginQuestion,
	loginType,
	memberType,
	onChangeLoginRadio,
	onChangeToggle,
	onConfirmAdult,
	onLoginButtonClick,
	orderUrl,
	returnUrl,
	seller
}: LoginViewProps) => {
	const {
		t
	} = useTranslation()

	const tooltip = useTooltip()

	const imageValidate = useImageValidate()
	const isReturnUrlAndMember = returnUrl && memberType === `member`

	const radioLabelClassName = classNameFunc(`text-[13px] font-bold`)

	return (
		<>
			<div
				className="mx-auto flex w-pc justify-center pt-8"
			>
				<form
					className="block min-h-[320px] w-[485px] border-r border-dashed border-[#a7a7a7] pl-15 pr-13"
					onSubmit={onLoginButtonClick}
				>
					{
						seller === `true` && (
							<p
								className="-mx-4 mt-2 text-center text-xs"
								dangerouslySetInnerHTML={
									{
										__html: t(`Qoo10 계정으로 로그인 후 셀러 가입을 진행할 수 있습니다. 계정이 아직 없으시다면, 회원등록을 먼저 진행해주세요.`)
									}
								}
							/>
						)
					}

					{
						seller !== `true` && (
							<>
								{
									memberType === `member` && (
										<LoginTypeToggleView
											onToggleChange={onChangeToggle}
											toggleData={loginType}
											text={
												[
													`メール or ID`,
													`携帯番号で`
												]
											}
										/>
									)
								}

								<div
									className="ml-32"
								>
									<RadioGroup
										defaultId="member"
										onChange={onChangeLoginRadio}
									>
										<Radio
											className="ml-1 size-4"
											id="member"
											labelClassName={radioLabelClassName}
											name="member"
											text="会員"
										/>

										<Radio
											className="ml-6 size-4"
											id="guest"
											labelClassName={radioLabelClassName}
											name="guest"
											text="ゲスト"
										/>
									</RadioGroup>
								</div>
							</>
						)
					}

					<div
						className="w-full space-y-2.5"
					>
						{
							memberType === `member` && (
								<LoginMemberCont
									inputRef={RefLoginInfo}
									loginType={loginType}
									onChangeToggle={onChangeToggle}
									seller={seller}
								/>
							)
						}

						{
							memberType === `guest` &&
							(
								!isGuestAfterGoodsLogin ?
									(
										<LoginGuestCont
											inputRef={RefLoginInfo}
										/>
									) :
									(
										<LoginGuestAfterGoodsCont
											orderUrl={orderUrl}
										/>
									)
							)
						}
					</div>

					{
						(!isGuestAfterGoodsLogin || isReturnUrlAndMember) && (
							<>
								<Button
									type="submit"
									className={
										classNameFunc(`
											ml-32 mt-5 flex h-11 w-[242px] items-center justify-center
											bg-[#517fef] font-bold text-dg-white
										`)
									}
									rounded
								>
									<div
										className="relative mr-1 h-3 w-2.5"
									>
										<Image
											alt="Lock Icon"
											className="object-cover"
											height={12}
											onError={imageValidate.onError}
											src="/images/pc/login/lock.png"
											width={10}
										/>
									</div>

									<span
										className="transition-colors"
									>
										ログイン
									</span>
								</Button>

								{
									memberType === `member` && loginType === `phone` && seller !== `true` && (
										<>
											<p
												className="ml-30 mt-1.5 text-xs"
											>
												携帯番号ログインとは?
												<Button
													className="ml-1 align-top"
													onClick={
														(event) => {
															tooltip.open({
																id: `tooltip-phonelogin`,
																target: event.currentTarget,
																text:
																	stringTrimFunc(`
																		携帯番号を利用してログインできます。
																		一度、メールまたはIDでログインし「My情報」でログイン用の携帯番号を設定してください。
																	`)
															})
														}
													}
												>
													<QuestionMarkCircleIcon
														className="size-4 text-[#517fef]"
													/>
												</Button>
											</p>

											<Tooltip
												className="m-0 !w-[500px] dark:text-dgdark-white"
												id="tooltip-phonelogin"
												width="w-72"
												rounded
											/>
										</>
									)
								}

								{
									seller !== `true` && (
										<LoginSnsArea
											returnUrl={returnUrl}
										/>
									)
								}

							</>
						)
					}

					{/* 성인인증관련 얼럿문구/팝업  */}
					{
						isAdult && (
							<LoginAdult
								onConfirmAdult={onConfirmAdult}
							/>
						)
					}

					{/* 얼럿메시지 노출부분 */}
					{
						alertMsg && (
							<div
								className={
									classNameFunc(`
										mt-5 flex items-center space-x-1.5 rounded-lg bg-[#ffe9e9]
										px-4 py-3 text-dg-red
									`)
								}
							>
								<Image
									alt="Caution"
									className="flex-none object-contain"
									height={16}
									onError={imageValidate.onError}
									sizes="16px"
									src="/images/pc/common-member/info-caution.png"
									width={16}
								/>

								<p
									className="leading-4.5 ml-1 text-xs"
									dangerouslySetInnerHTML={
										{
											__html: alertMsg
										}
									}
								/>
							</div>
						)
					}
				</form>

				{/* 우측 영역 뷰 */}
				<LoginGuideView
					isGuestAfterGoodsLogin={isGuestAfterGoodsLogin}
					isNoLoginQuestion={isNoLoginQuestion}
					memberType={memberType}
					seller={seller}
				/>
			</div>

			{seller !== `true` && <LoginReward />}
		</>
	)
}

export default LoginView
